<template>
  <div class="mt-4">
    <AppFixedPageTitle
      title="Gantt (Beta)"
      icon="/img/icons/icons8/ios/chemical_plant_white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :rangeParams="{ is_range: false }"
          @onClearClick="resetDefaultSearchBarFilter(true)"
          @onSearchClick="handleFilterDate(searchBarFilter.range.start)"
        >
          <AppSearchBarFilterSection
            name="Ponto de carga"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <PuzlSelect
                @input="applyGlobalFilter"
                class="w-100 mt-1"
                :disabled="!filter.company_plant_id || loading_charge_points"
                v-model="filter.charge_point_name"
                :items="$_charge_points"
                placeholder="Ponto de carga"
                customKey="name"
              />
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <CardsHeader
            :dontShowFinishedAndCanceled="
              filter.dont_show_finished_and_canceled
            "
            :showOnlyFinished="filter.show_only_finished"
            :loading="loading_headers"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <div class="col-md-12">
      <AppTabSelect
        :isMultiSelect="false"
        :items="transformedStatesForTabSelect"
        :isShowViewTypes="false"
        @onTabSelectItemClick="onTabSelectItemClick"
      >
      </AppTabSelect>
    </div>
    <div class="container-fluid mt-4">
      <SkeletonPuzlFullWidth v-if="loading_items" />
      <AppGantt
        v-if="itemsForGantt && itemsForGantt.length && !loading_items"
        :headers="headersForGantt"
        :items="itemsForGantt"
        @item-action="itemAction"
        @item-detail="itemDetail"
        @task-detail="taskDetail"
        @item-index="itemIndex"
        @status-detail="statusDetail"
      />
      <PuzlEmptyData
        v-else-if="
          (!itemsForGantt || (itemsForGantt && !itemsForGantt.length)) &&
          !loading_items
        "
      />
      <ModalEditScheduleTravel
        @updatedTravel="init"
        ref="modalEditScheduleTravel"
      />
      <ModalInfoSchedule ref="modalInfoSchedule" />
      <ModalTravelCycle @updatedCycle="init" ref="travelCycle" />
    </div>
  </div>
</template>

<script>
import CardsHeader from "./Shared/_CardsHeader";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import ModalEditScheduleTravel from "@/views/Modules/Operational/Schedule/Weighing/Shared/_ModalEditTravel";
import { mapGetters } from "vuex";
import moment from "moment";
import { ScheduleTravelEnum } from "@/enum/ScheduleTravelEnum";
import ModalInfoSchedule from "../Resume/Shared/_ModalInfoSchedule.vue";
import { base_url_ms } from "@/plugins";
import {
  AppSearchBar,
  AppSearchBarFilterSection,
  initSearchBarFilterType,
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppTabSelect,
  AppGantt,
} from "@/components/AppGlobal";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import ModalTravelCycle from "@/views/Modules/Operational/Schedule/Weighing/Shared/ScheduleTravelCycle/_ModalNewTravelCycle";

export default {
  name: "IndexGantt",
  components: {
    CardsHeader,
    MultiFilter,
    InputDatePicker,
    PuzlSelect,
    ModalEditScheduleTravel,
    AppSearchBarFilterSection,
    AppFixedPageTitle,
    AppPageHeader,
    AppSearchBar,
    AppPageHeaderActions,
    AppTabSelect,
    PuzlEmptyData,
    SkeletonPuzlFullWidth,
    ModalInfoSchedule,
    ModalTravelCycle,
    AppGantt,
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_items: "scheduleTravelGantt/getItems",
      $_charge_points: "plantChargePoint/fetch",
    }),
    transformedStatesForTabSelect() {
      return this.statesForTabSelect.map((state, index) => {
        let quantity = 0;
        if (state.id === null) {
          quantity = this.items.length;
        } else {
          this.items.forEach((item) => {
            if (item.status === state.id) {
              quantity += 1;
            }
          });
        }
        return {
          id: state.id,
          name: state.name,
          suffix: quantity,
          selected: index === 0 ? true : false,
        };
      });
    },
  },
  data() {
    return {
      // si el headersGantt.availability tiene menos de 24 itens, el resto se completara con arreglos asi [0, 0, 0, 0]
      // si el headersGantt.availability[i] tiene menos de 4 itens, el resto se completara con 0
      // validar la existencia de todos los parametros obligatorios
      // emilperezpiz
      headersGantt: [
        {
          title: "Pontos de carga disponíveis 8",
          iconSrc: "/img/icons/icons8/ios/tower.png",
          availability: [
            [20, 15, 30, 45],
            [30, 30, 30, 45],
            [10, 20, 30, 30],
            [30, 15, 30, 45],
            [10, 15, 30, 45],
            [40, 10, 30, 45],
            [50, 10, 30, 45],
            [60, 45, 30, 45],
            [70, 15, 30, 45],
            [80, 15, 30, 45],
            [0, 35, 30, 45],
            [40, 45, 30, 45],
            [30, 35, 30, 45],
            [40, 25, 30, 45],
            [50, 95, 30, 45],
            [60, 65, 30, 45],
            [10, 55, 30, 45],
            [20, 45, 30, 45],
            [20, 15, 30, 45],
            [40, 45, 30, 45],
            [50, 25, 30, 45],
            [80, 20, 30, 45],
            [10, 15, 30, 45],
            [20, 15, 30, 45],
          ],
        },
        {
          title: "Betoneiras disponíveis 10",
          iconSrc: "/img/icons/icons8/ios/truck.png",
          availability: [
            [0, 15, 30, 45],
            [0, 30, 30, 45],
            [10, 20, 30, 30],
            [30, 15, 30, 45],
            [10, 15, 30, 45],
            [40, 15, 30, 45],
            [50, 15, 30, 45],
            [60, 15, 30, 45],
            [70, 15, 30, 45],
            [80, 15, 30, 45],
            [0, 15, 30, 45],
            [40, 15, 30, 45],
            [90, 15, 30, 45],
            [30, 15, 30, 45],
            [50, 15, 30, 45],
            [50, 15, 30, 45],
            [50, 15, 30, 45],
            [20, 15, 30, 45],
            [10, 15, 30, 45],
            [40, 15, 30, 45],
            [30, 15, 30, 45],
            [10, 15, 30, 45],
            [60, 15, 30, 45],
            [20, 15, 30, 45],
          ],
        },
      ],
      base_url_ms: base_url_ms(),
      searchBarFilter: initSearchBarFilterType(),
      loading_headers: false,
      loading_items: false,
      loading_charge_points: true,
      filter: {
        date: moment().format("YYYY-MM-DD"),
        company_plant_id: null,
        company_plant_uuid: null,
        charge_point_name: null,
        dont_show_finished_and_canceled: false,
        show_only_finished: false,
        show_only_canceled: false,
      },
      items: [],
      ScheduleTravelEnum: ScheduleTravelEnum,
      itemsForGantt: [],
      headersForGantt: [],
      statesForTabSelect: this.initStatesForTabSelect(),
    };
  },
  methods: {
    init() {
      if (!this.filter.company_plant_id) {
        this.$notify({ type: "danger", message: "Central não selecionada!" });
      }
      delete this.filter.global;
      this.filter.charge_point_name = null;
      this.filter.dont_show_finished_and_canceled = false;
      this.filter.show_only_finished = false;
      this.filter.show_only_canceled = false;
      this.getHeaders();
      this.getItems();
    },

    async getHeaders() {
      this.loading_headers = true;
      await this.$store.dispatch("scheduleTravelGantt/getHeaders", this.filter);
      this.loading_headers = false;
    },

    async getItems() {
      this.loading_items = true;
      await this.$store.dispatch("scheduleTravelGantt/getItems", this.filter);
      this.items = this.$_items;
      this.applyFormat();
      this.loading_items = false;
    },

    clearFilters() {
      this.filter = {
        date: this.filter.date ?? moment().format("YYYY-MM-DD"),
        company_plant_id: this.filter.company_plant_id,
        company_plant_uuid: this.filter.company_plant_uuid,
        charge_point_name: null,
      };
      this.$refs["date-picker"].resetDate();
      this.init();
    },

    handleFilterDate(date) {
      this.filter.company_plant_id =
        this.searchBarFilter.company_plant_selected;
      this.filter.global = this.searchBarFilter.custom_search_values;

      this.filter.company_plant_uuid = this.getCompanyPlantSelectedById(
        this.filter.company_plant_id
      )?.uuid;

      if (
        this.getDefaultCompanyPlant() !==
          +this.searchBarFilter.company_plant_selected ||
        this.filter.date !== moment(date).format("YYYY-MM-DD")
      ) {
        this.filter.date = moment(date).format("YYYY-MM-DD");
        this.filter.charge_point_name = null;
        this.filter.dont_show_finished_and_canceled = false;
        this.init();
        this.setDefaultCompanyPlant();
        this.getCompanyPlantChargePoints();
        return;
      }

      this.applyGlobalFilter(this.filter);
    },

    applyGlobalFilter(filter) {
      const global_filter =
        filter && filter.global && filter.global.length ? filter.global : [];
      this.filter.charge_point_name &&
        global_filter.push(this.filter.charge_point_name);
      // Filtragem por palavras
      this.items = this.$_items.filter((obj) => {
        return global_filter.every((value) =>
          [
            obj.description,
            obj.equipment_name,
            obj.contract_proposal_code,
            obj.charge_point_name,
            obj.schedule_id,
          ].some(
            (prop) =>
              value &&
              prop &&
              prop
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase())
          )
        );
      });
      // Não mostra viagens finalizadas, não realizadas e canceladas
      if (this.filter.dont_show_finished_and_canceled) {
        this.items = this.items.filter(
          (obj) =>
            ![
              ScheduleTravelEnum.CANCELED,
              ScheduleTravelEnum.FINISHED,
              ScheduleTravelEnum.UNREALIZED,
              ScheduleTravelEnum.IN_PROGRESS,
            ].includes(obj.status)
        );
      } else if (this.filter.show_only_finished) {
        this.items = this.items.filter((obj) =>
          [
            ScheduleTravelEnum.FINISHED,
            ScheduleTravelEnum.IN_PROGRESS,
          ].includes(obj.status)
        );
      } else if (this.filter.show_only_canceled) {
        this.items = this.items.filter((obj) =>
          [ScheduleTravelEnum.CANCELED].includes(obj.status)
        );
      }

      this.applyFormat();
    },

    applyFormat() {
      this.items.map((item, index) => {
        item.index = ++index;
        item.start = this.getStart(item);
        item.end = this.getEnd(item);
        item.seal = item.seal ? item.seal : "-";
        item.driver_name = item.driver_name ? item.driver_name : "-";
        item.label = moment(item.start).format("HH:mm");
        item.label += ` | ${item.volume.toFixed(1)} M³`;
        item.label += item.sequencial_number
          ? ` | O.S ${item.sequencial_number}`
          : "";
        item.label += item.danfe_number ? ` | N.F. ${item.danfe_number}` : "";
        item.equipment_name = item.equipment_name ?? "N/A";
        const color = this.getItemColor(item);
        item.style = {
          base: color,
        };
        return item;
      }, this);

      this.items
        .sort((a, b) => a.start - b.start)
        .map((item, index) => {
          item.index = ++index;
          return item;
        });
      this.hydrate();
    },

    /**
     * @param {object} item
     * @param {number} item.status
     * @param {?number} status.substatus
     * @returns {{fll: string, stroke: string, text: string}}
     */
    getItemColor(item) {
      const status = item.status;
      const substatus = item.sub_status;
      switch (status) {
        // Concluído
        case ScheduleTravelEnum.FINISHED:
          return {
            fill: "#f2f4f9",
            stroke: "#1a70b7",
            text: "Concluído",
          };
        // Caregando
        case ScheduleTravelEnum.LOADING:
          return {
            fill: "#C5A8E6",
            stroke: "#C5A8E6",
            text: "Caregando",
          };
        // Cancelado
        case ScheduleTravelEnum.CANCELED:
          return {
            fill: "#FCF3F3",
            stroke: "#db4539",
            text: "Cancelado",
          };
        // Em andamento 5
        case ScheduleTravelEnum.IN_PROGRESS:
          const substatusEnum = Object.freeze({
            RELEASED: 0, // Liberada
            CHARGING: 1, // Carregando
            DOSING: 2, // Dosando
            ON_THE_WAY: 3, // A caminho
            WAITING: 4, // Esperando
            DISCHARGING: 5, // Descarregando
            RETURNING: 6, // Retornando
            CLEANING: 7, // Lastro
            STATUS_LEAVING_CONSTRUCTION: 8, // Saindo da obra
          });
          let result = {
            fill: "#E8E8E8",
            stroke: "#11cdef",
            text: "Andamento",
          };

          if (substatus === substatusEnum.DOSING) {
            result.fill = "#11cdef";
          } else if (substatus === substatusEnum.ON_THE_WAY) {
            result.fill = "#f2b532";
          } else if (substatus === substatusEnum.WAITING) {
            result.fill = "#db4539";
          } else if (substatus === substatusEnum.DISCHARGING) {
            result.fill = "#149e57";
          } else if (
            [
              substatusEnum.STATUS_LEAVING_CONSTRUCTION,
              substatusEnum.RETURNING,
            ].includes(substatus)
          ) {
            result.fill = "#D3D3E8";
          } else if (substatus === substatusEnum.CLEANING) {
            result.fill = "#f3a4b5";
          }
          return result;
        // Não realizado
        case ScheduleTravelEnum.UNREALIZED:
          return {
            fill: "#fef9f2",
            stroke: "#f2b532",
            text: "Não realizado",
          };
        // Padrão
        default:
          return {
            // fill: "#E8E8E8",
            // stroke: "#E8E8E8",
            fill: "#f2f7f3",
            stroke: "#149e57",
            text: "Liberado",
          };
      }
    },

    setDefaultCompanyPlant() {
      localStorage.setItem(
        "company_plant_id_to_schedule_travel_gantt",
        this.filter.company_plant_id
      );
    },

    getDefaultCompanyPlant() {
      const company_plant_id = localStorage.getItem(
        "company_plant_id_to_schedule_travel_gantt"
      );
      return company_plant_id ? parseInt(company_plant_id) : null;
    },

    applyContractProposalFilter(contract_proposal_code) {
      if (
        !this.$refs["multi-filter"] ||
        (this.$refs["multi-filter"] &&
          this.$refs["multi-filter"].global.includes(contract_proposal_code))
      ) {
        return;
      }
      this.$refs["multi-filter"].global.push(contract_proposal_code);
      this.$refs["multi-filter"].handleFilterClick();
    },

    applyScheduleFilter(schedule_id) {
      this.items = this.items.filter(
        (item) => item.schedule_id === schedule_id
      );
      this.hydrate();
    },

    async handleModalEditScheduleTravel(schedule_travel) {
      if (
        [
          ScheduleTravelEnum.CANCELED,
          ScheduleTravelEnum.FINISHED,
          ScheduleTravelEnum.IN_PROGRESS,
          ScheduleTravelEnum.UNREALIZED,
          ScheduleTravelEnum.LOADING,
        ].includes(schedule_travel.status)
      ) {
        return this.$notify({
          type: "danger",
          message: "Viagem não apta para edição!",
        });
      }

      const loader = this.$loading.show();
      const response = await this.$store.dispatch("travels/show", {
        uuid: schedule_travel.uuid, // uuid da viagem
        code: "1", // direcionamento para resource
      });

      this.$refs.modalEditScheduleTravel.openModal(response.data);
      loader.hide();
    },

    getStart(schedule_travel) {
      switch (true) {
        case schedule_travel.start_cycle !== null:
          return new Date(schedule_travel.start_cycle);
        case schedule_travel.start_timer !== null &&
          ![
            ScheduleTravelEnum.CANCELED,
            ScheduleTravelEnum.UNREALIZED,
          ].includes(schedule_travel.status):
          return new Date(schedule_travel.start_timer);
        default:
          return new Date(schedule_travel.load_truck_time);
      }
    },

    getEnd(schedule_travel) {
      switch (true) {
        case schedule_travel.end_cycle !== null:
          return new Date(schedule_travel.end_cycle);
        case schedule_travel.start_timer !== null &&
          ![
            ScheduleTravelEnum.CANCELED,
            ScheduleTravelEnum.UNREALIZED,
          ].includes(schedule_travel.status):
          const start = moment(schedule_travel.start_timer);
          const diffMinutes = moment(schedule_travel.load_truck_time).diff(
            moment(schedule_travel.free_for_travel),
            "minutes"
          );
          const resultDate = start
            .add(Math.abs(diffMinutes), "minutes")
            .format("YYYY-MM-DD HH:mm:ss");
          return new Date(resultDate);
        default:
          return new Date(schedule_travel.free_for_travel);
      }
    },

    handleVisibilityChange() {
      if (document.visibilityState === "visible") {
        this.init();
      }
    },

    async getCompanyPlantChargePoints() {
      if (!this.filter.company_plant_id) {
        return (this.loading_charge_points = false);
      }

      this.loading_charge_points = true;
      await this.$store.dispatch(
        "plantChargePoint/getActiveByPlant",
        this.filter.company_plant_id
      );
      this.loading_charge_points = false;
    },

    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        statusId: null,
        company_plant_selected: this.filter.company_plant_id,
        range: {
          items: [],
          selected: null,
          start: this.filter.date,
          end: this.filter.date,
        },
        custom_search_values: [],
      };
    },
    resetDefaultSearchBarFilter(execRequest = false) {
      this.searchBarFilter = this.defaultSearchBarFilter();
      this.searchBarFilter.range.start = this.filter.date;
      this.searchBarFilter.range.end = this.filter.date;
      if (execRequest) {
        this.init();
      }
    },
    /**
     * @param {object} current
     * @param {number|string} current.taskId
     * @param {number|string} current.itemId
     * @returns {void}
     */
    taskDetail(current) {
      const popoverElement = document.querySelector(
        `.popover-task-detail-${current.itemId}-${current.taskId}`
      );
      if (popoverElement) {
        popoverElement.innerHTML = this.popoverTaskTemplate(current);
      }
    },
    /**
     * @param {object} current
     * @param {number|string} current.taskId
     * @param {number|string} current.itemId
     * @returns {HTMLElement}
     */
    popoverTaskTemplate(current) {
      const item = this.getItemById(current.itemId);
      if (!item) {
        return;
      }
      const buttonNfe = `<div
                          onclick="EventBus.$emit('download_nf', '${item.id}')"
                          id="task-template-${item.id}"
                          class="d-flex justify-content-center align-items-center pointer"
                          style="flex: 1; border: 1px solid #E8E8E8; border-radius: 4px; gap: 8px; height: 24.25px;">
                          <img
                            src="/img/nfe.png"
                            style="margin: 0 2px"
                            width="20"
                            height="20"
                            alt="..."
                          />
                          <span>N. ${item.danfe_number}</span>
                        </div>`;
      const buttonOS = `<div onclick="EventBus.$emit('download_os', '${item.uuid}')" class="d-flex justify-content-center align-items-center pointer"
                          style="flex: 1; border: 1px solid #E8E8E8; border-radius: 4px; gap: 8px; height: 24.25px;">
                          <img
                            src="/img/icons/os.png"
                            style="margin: 0 2px"
                            width="20"
                            height="20"
                            alt="..."
                          />
                          <span>N. ${item.sequencial_number}</span>
                        </div>

                        `;
      return `<div class="d-flex justify-content-between" style="border-bottom: 0.5px solid #E8E8E8; width: 343px;">
                <span class="text-left"> Informações da viagem </span>
                <img onclick="EventBus.$emit('travel_cycle', '${item.id}')"
                  width="24"
                  height="24"
                  src="/img/icons/icons8/ios/clock_green.png"
                  class="mt-n2 pointer"
                  alt="..."
                />
              </div>
              <div class="d-flex flex-column mt-3" style="border-bottom: 0.5px solid #E8E8E8;">
                <span class="mb-1" style="font-family: Fredoka, sans-serif; font-weight: 300; font-size: 12px;"><strong>${
                  item.contract_proposal_code
                }</strong></span>
                <span class="mb-1">Obra - ${
                  item.constructions_construction_name
                }</span>
              </div>
              <div class="d-flex flex-column mt-3" style="border-bottom: 0.5px solid #E8E8E8;">
                <span class="mb-1" style="font-family: Fredoka, sans-serif; font-weight: 300; font-size: 12px;">Peça: ${
                  item.mix_concreted_pieces_description
                }</span>
                <span class="mb-1" style="font-family: Fredoka, sans-serif; font-weight: 300; font-size: 12px;" title="${
                  item.contract_proposal_formulations_feature
                }">Traço: ${this.truncateText(
        item.contract_proposal_formulations_feature,
        50
      )}</span>
              </div>
              <div class="d-flex flex-column mt-3">
                <span class="mb-1" style="font-family: Fredoka, sans-serif; font-weight: 300; font-size: 12px;">Equipamento: ${
                  item.equipment_name
                }</span>
                <span class="mb-1" style="font-family: Fredoka, sans-serif; font-weight: 300; font-size: 12px;">Motorista: ${
                  item.driver_name
                }</span>
                <span class="mb-1" style="font-family: Fredoka, sans-serif; font-weight: 300; font-size: 12px;">Lacre: ${
                  item.seal
                }</span>
              </div>
              <div class="d-flex flex-row mt-2"
                style="gap: 5px;">
                ${item.danfe_number ? buttonNfe : ""}
                ${item.sequencial_number ? buttonOS : ""}
              </div>
              `;
    },
    /**
     * @param {string|number} id
     * @returns {void}
     */
    itemAction(id) {
      const currentItem = this.getItemById(id);
      if (!currentItem) {
        return;
      }
      this.$refs.modalInfoSchedule.openModal(
        this.filter.company_plant_uuid,
        currentItem.constructions_id,
        moment(currentItem.start).format("YYYY-MM-DD")
      );
    },
    /**
     * @param {string|number} id
     * @returns {void}
     */
    itemDetail(id) {
      const currentItem = this.getItemById(id);
      if (!currentItem) {
        return;
      }
      this.searchBarFilter.custom_search_values = [currentItem.schedule_id];
      this.applyScheduleFilter(currentItem.schedule_id);
    },
    /**
     * @param {string|number} id
     * @returns {void}
     */
    itemIndex(id) {
      const item = this.getItemById(id);
      if (!item) {
        return;
      }
      var currentUrl = window.location.href;
      var chargingUrl = currentUrl.split("schedule-travel-puzl-gantt")[0];
      chargingUrl = `${chargingUrl}schedule/charging/${item.uuid}`;
      window.open(chargingUrl);
    },
    /**
     * @param {string|number} id
     * @returns {void}
     */
    statusDetail(id) {
      const popoverElement = document.querySelector(
        `.popover-status-detail-${id}`
      );
      if (popoverElement) {
        popoverElement.innerHTML = this.popoverStatusTemplate(id);
      }
    },
    /**
     * @param {string|number} id
     * @returns {HTMLElement}
     */
    popoverStatusTemplate(id) {
      const item = this.getItemById(id);
      if (!item) {
        return;
      }
      return `<div style="border-bottom: 0.5px solid #E8E8E8; width: 343px;">
                <span class="text-left"> Status da viagem </span>
              </div>
              <div class="d-flex flex-column mt-3" style="border-bottom: 0.5px solid #E8E8E8;">
                <div
                  class="d-flex flex-row justify-content-center align-items-center mt-n2 mb-2"
                  style="background-color: ${
                    this.getItemColor(item)["fill"]
                  }; border-radius: 16px; gap: 4px; max-width: 130px; height: 18px">
                  <span style="font-family: Fredoka, sans-serif; font-weight: 400; color: ${
                    this.getItemColor(item)["stroke"]
                  };">${this.getItemColor(item)["text"]}</span>
                </div>
                <span class="mb-1"><strong>${
                  item.contract_proposal_code
                }</strong></span>
                <span class="mb-1">Obra - ${
                  item.constructions_construction_name
                }</span>
              </div>
              <!--<div class="d-flex flex-row mt-3">
                <div class="d-flex flex-row" style="flex: 2;">
                  Viagem
                </div>
                <div class="d-flex" style="width: 40;">
                  2/5
                </div>
                <div class="d-flex" style="width: auto;">
                </div>
              </div>-->
              `;
    },
    /**
     * @param {string|number} id
     * @returns {?Object}
     */
    getItemById(id) {
      let item = this.items.find((item) => {
        return item.id === id;
      });
      if (!item || item === undefined) {
        return null;
      }
      return item;
    },
    /**
     * @param {object} item
     * @param {number} item.status
     * @param {?number} status.substatus
     * @returns {{iconSrc: string, percent: number}}
     */
    getStatusIcon(item) {
      let percent = 0;
      let status = item.status;
      let substatus = item.sub_status;

      switch (status) {
        // Concluído
        case ScheduleTravelEnum.FINISHED:
          let scheduleTravelCyclesFreeForTravel = moment(item.end_cycle); // fim da viagem "realizado"
          let freeForTravel = moment(item.free_for_travel); // fim da viagem "previsto"
          percent = (scheduleTravelCyclesFreeForTravel * 100) / +freeForTravel;
          return {
            iconSrc: "/img/icons/icons8/ios/double-tick_primary.png",
            percent: percent,
            red: "/img/icons/icons8/ios/double-tick_primary.png",
          };
        // Caregando 2
        case ScheduleTravelEnum.LOADING:
          let loadingStartTimer = moment(item.load_truck_time); // início do carreg. previsto
          let currentTime = moment();
          let diffCurrentTimeLoadingStartTimer = currentTime.diff(
            loadingStartTimer,
            "minutes"
          );
          let loadTruckDiff = +item.load_truck_diff.replace(/[^0-9]/g, "");
          percent = (diffCurrentTimeLoadingStartTimer * 100) / +loadTruckDiff;

          return {
            iconSrc: `/img/icons/icons8/ios/${
              percent <= 100
                ? "chemical-plant-v1.png"
                : "chemical-plant_danger.png"
            }`,
            percent: percent,
          };
        // Cancelado
        case ScheduleTravelEnum.CANCELED:
          return {
            iconSrc: "/img/icons/icons8/ios/unavailable_danger.png",
            percent: percent,
            red: "/img/icons/icons8/ios/worker.png",
          };
        // Em andamento 5
        case ScheduleTravelEnum.IN_PROGRESS:
          const substatusEnum = Object.freeze({
            RELEASED: 0, // Liberada
            CHARGING: 1, // Carregando
            DOSING: 2, // Dosando
            ON_THE_WAY: 3, // A caminho
            WAITING: 4, // Esperando
            DISCHARGING: 5, // Descarregando
            RETURNING: 6, // Retornando
            CLEANING: 7, // Lastro
            STATUS_LEAVING_CONSTRUCTION: 8, // Saindo da obra
          });
          if ([substatusEnum.DOSING].includes(+substatus)) {
            if (item.start_timer) {
              let loadingStartTimer = moment(item.start_timer); // início do carreg. realizado
              let currentTime = moment();
              let diffCurrentTimeLoadingStartTimer = currentTime.diff(
                loadingStartTimer,
                "minutes"
              );
              let loadTruckTime = moment(item.load_truck_time).utc(); // início do carreg. previsto
              let expectedPlantExitTime = moment(
                item.expected_plant_exit_time,
                "DD MMM YYYY HH:mm",
                "pt"
              ).utc(); // saida da central prevista
              let diffExpectedPlantExitTimeLoadTruckTime =
                expectedPlantExitTime.diff(loadTruckTime, "minutes");
              console.log(diffExpectedPlantExitTimeLoadTruckTime);
              percent = Math.abs(
                diffCurrentTimeLoadingStartTimer /
                  diffExpectedPlantExitTimeLoadTruckTime
              );
            }
            return {
              iconSrc: `/img/icons/icons8/ios/${
                percent <= 100
                  ? "chemical-plant-v1.png"
                  : "chemical-plant_danger.png"
              }`,
              percent: percent,
              red: "/img/icons/icons8/ios/truck.png",
            };
          }
          if ([substatusEnum.ON_THE_WAY].includes(+substatus)) {
            let scheduleTravelCyclesPlantExitTime = moment(
              item.schedule_travel_cycles_plant_exit_time
            ); // Saída da central
            let currentTime = moment();
            let diffCurrentTimeLoadingStartTimer = currentTime.diff(
              scheduleTravelCyclesPlantExitTime,
              "minutes"
            );
            let sendRouteTime = +item.send_route_time.replace(/[^0-9]/g, "");
            percent = (diffCurrentTimeLoadingStartTimer * 100) / +sendRouteTime;
            return {
              iconSrc: `/img/icons/icons8/ios/${
                percent <= 100 ? "truck_reverse.png" : "truck_reverse_red.png"
              }`,
              percent: percent,
            };
          } else if (
            [
              substatusEnum.WAITING,
              substatusEnum.DISCHARGING,
              substatusEnum.STATUS_LEAVING_CONSTRUCTION,
            ].includes(+substatus)
          ) {
            let arrivalTime = moment(item.schedule_travel_cycles_arrival_time); // chegada na obra
            let currentTime = moment();
            let diffCurrentTimeArrivalTime = currentTime.diff(
              arrivalTime,
              "minutes"
            );
            let construction_stay_time = +item.construction_stay_time.replace(
              /[^0-9]/g,
              ""
            );
            percent =
              (diffCurrentTimeArrivalTime * 100) / construction_stay_time;

            return {
              iconSrc: `/img/icons/icons8/ios/${
                percent <= 100 ? "worker.png" : "worker_red.svg"
              }`,
              percent: percent,
            };
          } else if (
            [substatusEnum.RETURNING, substatusEnum.CLEANING].includes(
              +substatus
            )
          ) {
            let constructionExistTime = moment(
              item.schedule_travel_cycles_construction_exit_time
            ); // saida na obra
            let currentTime = moment();
            let diffCurrentTimeConstructionExistTime = currentTime.diff(
              constructionExistTime,
              "minutes"
            );
            let return_route_time = +item.return_route_time.replace(
              /[^0-9]/g,
              ""
            );
            percent =
              (diffCurrentTimeConstructionExistTime * 100) / return_route_time;
            return {
              iconSrc: "/img/icons/icons8/ios/truck.png",
              percent: percent,
            };
          }
        // Não realizado 7
        case ScheduleTravelEnum.UNREALIZED:
          return {
            iconSrc: null,
            percent: percent,
          };
        // Padrão
        default:
          return {
            iconSrc: null,
            percent: percent,
          };
      }
    },
    /**
     * @param {object} item
     * @param {Date} item.start
     * @param {number} item.volume
     * @param {number} item.sequencial_number
     * @param {number} item.danfe_number
     * @returns {{description: string, iconSrc: ?string, callback: ?function}[]}
     */
    changeFormatDetails(item) {
      const details = [];
      if (item.start) {
        details.push({
          description: moment(item.start).format("HH:mm"),
          iconSrc: null,
          callback: null,
        });
      }
      if (item.volume) {
        details.push({
          description: `${item.volume.toFixed(1)} M³`,
          iconSrc: null,
          callback: null,
        });
      }
      if (item.sequencial_number) {
        details.push({
          description: `O.S ${item.sequencial_number}`,
          iconSrc: "/img/icons/os.png",
          callback: (item) => {
            this.downloadOS(item.uuid);
            return;
          },
        });
      }
      if (item.danfe_number) {
        details.push({
          description: `N.F ${item.danfe_number}`,
          iconSrc: "/img/nfe.png",
          callback: (item) => {
            this.downloadNF(item.id);
            return;
          },
        });
      }
      return details;
    },

    /**
     * @param {string} id
     * @returns {void}
     */
    downloadNF(id) {
      let loader = this.$loading.show();
      this.$Progress.start();
      this.$store
        .dispatch("travels/getXmlByTravel", id)
        .then(async (response) => {
          if (response.data.status && response.data.status === 2) {
            return this.$notify({
              type: "danger",
              message:
                "Não foi possível realizar o download da Danfe. Nota rejeitada",
            });
          }
          if (!response.data.pdf_file_path) {
            return this.$notify({
              type: "danger",
              message:
                "Não foi possível realizar o download da Danfe. Emissão em andamento",
            });
          }
          const url =
            this.base_url_ms + "download-s3?url=" + response.data.pdf_file_path;
          let file_name = `${response.data.key}.pdf`;
          this.$store
            .dispatch("exports/download", url)
            .then(async (response) => {
              let blob = new Blob([response], {
                type: "application/pdf",
              });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.setAttribute("download", `${file_name}`);
              await link.click();
            });
        })
        .catch((error) => {
          return this.$notify({
            type: "danger",
            message:
              "Não foi possível realizar o download da Danfe. Emissão em andamento",
          });
        })
        .finally(() => {
          this.$Progress.finish();
          loader.hide();
        });
    },
    /**
     * @param {string} uuid
     * @returns {void}
     */
    downloadOS(uuid) {
      let params = {
        uuid: uuid,
        type: "proof",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then((response) => {
          let blob = new Blob([response], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "custom_name.pdf");
          window.open(link, "_blank");
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
        });
      return;
    },
    /**
     * @param {Object} params
     * @param {string} params.uuid
     * @param {string} params.schedules_address_construction_uuid
     * @returns {void}
     */
    travelCycle(params) {
      this.$refs.travelCycle.openModal(
        params.uuid,
        params.schedules_address_construction_uuid
      );
    },
    /**
     * @returns {void}
     */
    hydrate() {
      const times = [];
      this.itemsForGantt = this.items.map((item, index) => {
        const tasks = [];
        times.push({ start: item.start, end: item.end });
        let task = {
          id: item.id,
          uuid: item.uuid,
          start_time: item.start,
          end_time: item.end,
          details: this.changeFormatDetails(item),
          openPopoverWithClick: true, //emilperezpiz
          iconSrcList: [
            "/img/icons/icons8/ios/chemical-plant-v1.png",
            "/img/icons/icons8/ios/truck.png",
            "/img/icons/icons8/ios/worker.png",
            "/img/icons/icons8/ios/truck_reverse.png",
          ],
          color: this.getItemColor(item).fill,
        };
        tasks.push(task);
        let randomPercent = 0;
        if (sessionStorage.getItem(item.id)) {
          randomPercent = +sessionStorage.getItem(item.id);
        } else {
          randomPercent = Math.floor(Math.random() * 101);
          sessionStorage.setItem(item.id, String(randomPercent));
        }

        return {
          index: index + 1,
          id: item.id,
          iconSrc: "/img/icons/icons8/ios/wifi_gray.png",
          title: `${item.contract_proposal_code} ${item.constructions_construction_name} (${item.mix_concreted_pieces_description})`,
          status: {
            percent: parseInt(this.getStatusIcon(item).percent, 10),
            iconSrc: this.getStatusIcon(item).iconSrc,
            situation: item.status,
            openPopoverWithClick: true,
          },
          tasks: tasks,
        };
      });
      const chargingPointsAvailable =
        this.calculateAvailableChargingPoints(times);
      const concreteMixerAvailable =
        this.calculateAvailableConcreteMixer(times);
      this.headersForGantt = [];
      this.headersForGantt.push({
        title: `Pontos de carga disponíveis ${chargingPointsAvailable.total}`,
        iconSrc: "/img/icons/icons8/ios/tower.png",
        availability: chargingPointsAvailable.availability,
      });
      this.headersForGantt.push({
        title: `Betoneiras disponíveis ${concreteMixerAvailable.total}`,
        iconSrc: "/img/icons/icons8/ios/concrete-mixer-dark.png",
        availability: concreteMixerAvailable.availability,
      });
    },
    /**
     * @param {{start: Date, end: Date}[]} times
     * @returns {available: Object[], total: number}
     */
    calculateAvailableChargingPoints(times) {
      const availability = Array.from({ length: 24 }, () => [0, 0, 0, 0]);

      const chargePoints = this.$_charge_points.length;

      times.forEach((time) => {
        const date = new Date(time.start);

        const hour = date.getHours();
        const minutes = date.getMinutes();

        let intervalIndex = 0;
        if (minutes >= 0 && minutes < 15) intervalIndex = 0;
        else if (minutes >= 15 && minutes < 30) intervalIndex = 1;
        else if (minutes >= 30 && minutes < 45) intervalIndex = 2;
        else if (minutes >= 45 && minutes < 60) intervalIndex = 3;

        availability[hour][intervalIndex]++;
      });

      let total = 0;
      return {
        availability: availability.map((available) => {
          available.forEach((minute, index) => {
            total += minute;
            available[index] = chargePoints - minute;
          });
          return available;
        }),
        total: total,
      };
    },
    /**
     * @param {{start: Date, end: Date}[]} times
     */
    calculateAvailableConcreteMixer(times) {
      const availability = Array.from({ length: 24 }, () => [0, 0, 0, 0]);

      times.forEach(({ start, end }) => {
        const startHour = start.getHours();
        const startMinute = start.getMinutes();
        const endHour = end.getHours();
        const endMinute = end.getMinutes();

        const startTotalMinutes = startHour * 60 + startMinute;
        const endTotalMinutes = endHour * 60 + endMinute;

        for (
          let time = startTotalMinutes;
          time <= endTotalMinutes;
          time += 15
        ) {
          const hour = Math.floor(time / 60);
          const interval = Math.floor((time % 60) / 15);
          if (hour < 24) {
            availability[hour][interval]++;
          }
        }
      });

      let total = 10;
      return {
        availability: availability.map((available) => {
          available.forEach((minute, index) => {
            available[index] = total - minute;
          });
          return available;
        }),
        total: total,
      };
    },
    /**
     * @param {number} status
     * @returns {{id: ?number, name: string, quantity: number}}
     */
    initStatesForTabSelect() {
      return [
        { id: null, name: "Todos", quantity: 0 },
        { id: 0, name: "A Realizar", quantity: 0 },
        { id: ScheduleTravelEnum.FINISHED, name: "Concluídas", quantity: 0 },
        { id: ScheduleTravelEnum.CANCELED, name: "Canceladas", quantity: 0 },
      ];
    },
    /**
     * @param {object} status
     * @param {number|null} status.id
     * @param {string} status.name
     * @param {boolean} status.selected
     * @param {string} status.suffix
     * @returns {void}
     */
    onTabSelectItemClick(status) {
      switch (status.id) {
        case null:
          this.filter.show_only_finished = false;
          this.filter.dont_show_finished_and_canceled = false;
          this.applyGlobalFilter();
          break;
        case 0:
          this.filter.show_only_finished = false;
          this.filter.dont_show_finished_and_canceled = true;
          this.filter.show_only_canceled = false;
          this.applyGlobalFilter();
          break;
        // Concluído
        case ScheduleTravelEnum.FINISHED:
          this.filter.show_only_finished = true;
          this.filter.dont_show_finished_and_canceled = false;
          this.filter.show_only_canceled = false;
          this.applyGlobalFilter();
          break;
        // Caregando
        case null:
          break;
        // Cancelado
        case ScheduleTravelEnum.CANCELED:
          this.filter.show_only_finished = false;
          this.filter.dont_show_finished_and_canceled = false;
          this.filter.show_only_canceled = true;
          this.applyGlobalFilter();
          break;
        // Em andamento
        case 5:
          break;
        // Não realizado
        case 7:
          break;
      }
    },
    /**
     * @param {string} text
     * @param {?number} quantityMax
     * @returns {string}
     */
    truncateText(text, quantityMax = 40) {
      return text.length > quantityMax
        ? text.slice(0, quantityMax - 3) + "…"
        : text;
    },
    /**
     * @param {number} companyPlantId
     * @returns {Object} companyPlant
     * @returns {Object} companyPlant.address_plant
     * @returns {number} companyPlant.bank_account_id
     * @returns {Object[]} companyPlant.charge_points
     * @returns {number} companyPlant.company_id
     * @returns {Object[]} companyPlant.company_issuers
     * @returns {string} companyPlant.company_name
     * @returns {Object} companyPlant.cycle_setting
     * @returns {Object} companyPlant.general_setting
     * @returns {number} companyPlant.id
     * @returns {string} companyPlant.name
     * @returns {boolean} companyPlant.requires_municipal_building_code
     * @returns {string} companyPlant.short_name
     * @returns {boolean} companyPlant.status
     * @returns {boolean} companyPlant.test
     * @returns {string} companyPlant.totvs_code
     * @returns {string} companyPlant.uuid
     */
    getCompanyPlantSelectedById(companyPlantId) {
      let companyPlant = null;
      for (const currentCompanyPlant of this.$_company_plants) {
        if (currentCompanyPlant.id === companyPlantId) {
          companyPlant = currentCompanyPlant;
          break;
        }
      }
      return companyPlant;
    },
  },
  watch: {
    $_company_plants(newValue, oldValue) {
      if (newValue) {
        this.resetDefaultSearchBarFilter(true);
      }
    },
  },
  mounted() {
    this.filter.company_plant_id = this.getDefaultCompanyPlant();
    this.init();
    this.getCompanyPlantChargePoints();
    this.filter.company_plant_uuid = this.getCompanyPlantSelectedById(
      this.filter.company_plant_id
    )?.uuid;

    EventBus.$on("download_nf", (id) => {
      this.downloadNF(id);
    });

    EventBus.$on("download_os", (uuid) => {
      this.downloadOS(uuid);
    });

    EventBus.$on("travel_cycle", (id) => {
      const item = this.getItemById(+id);
      if (!item) {
        return;
      }
      this.travelCycle({
        uuid: item.uuid,
        schedules_address_construction_uuid:
          item.schedules_address_construction_uuid,
      });
    });

    document.addEventListener("visibilitychange", this.handleVisibilityChange);

    this.resetDefaultSearchBarFilter();
  },

  beforeDestroy() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  },
};
</script>

<style>
#schedule-travel-gantt-filter .card {
  border: none !important;
  box-shadow: none !important;
  margin-bottom: 0 !important;
}

#schedule-travel-gantt-filter .card-body {
  padding-bottom: 0.8rem !important;
}
</style>
