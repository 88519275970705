<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title pl-2 ml-1">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <breadcrumb
                list-classes="bg-success border-header-primary-bottom"
              >
                Programação e Pesagem
              </breadcrumb>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Emissão de nota fiscal
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <el-popover v-if="audits?.nfe?.user_name"
                                class="ml-0"
                                placement="bottom"
                                trigger="click"
                    >
                      <h5 style="margin-bottom: -1px;font-weight: 400 !important;">
                        <span >{{ audits.nfe.latest_updated_at | parseDate('DD MMM YYYY - HH:mm') }} <br> {{ audits.nfe.user_name}}</span>
                      </h5>
                      <base-button
                        slot="reference"
                        class="p-0 m-0 shadow-none mr-2 mt-n4"
                        outiline
                        size="sm"
                        type="secundary"
                      >
                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                      </base-button>
                    </el-popover>

                    <base-switch v-model="generalSettings.nfe" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Exigir preenchimento de "CNO" em propostas/contratos
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <el-popover v-if="audits?.is_cno_required_prop_contracts?.user_name"
                                class="ml-0"
                                placement="bottom"
                                trigger="click"
                    >
                      <h5 style="margin-bottom: -1px;font-weight: 400 !important;">
                        <span >{{ audits.is_cno_required_prop_contracts.latest_updated_at | parseDate('DD MMM YYYY - HH:mm') }} <br> {{ audits.is_cno_required_prop_contracts.user_name}}</span>
                      </h5>
                      <base-button
                        slot="reference"
                        class="p-0 m-0 shadow-none mr-2 mt-n4"
                        outiline
                        size="sm"
                        type="secundary"
                      >
                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                      </base-button>
                    </el-popover>

                    <base-switch
                      v-model="generalSettings.is_cno_required_prop_contracts"
                      type="primary"
                      offText="Não"
                      onText="Sim"
                      class="success" />
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Exigir preenchimento de "Cód. de obra da prefeitura" em propostas/contratos
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <el-popover v-if="audits?.is_municipal_work_code_required_prop_contracts?.user_name"
                                class="ml-0"
                                placement="bottom"
                                trigger="click"
                    >
                      <h5 style="margin-bottom: -1px;font-weight: 400 !important;">
                        <span >{{ audits.is_municipal_work_code_required_prop_contracts.latest_updated_at | parseDate('DD MMM YYYY - HH:mm') }} <br> {{ audits.is_municipal_work_code_required_prop_contracts.user_name}}</span>
                      </h5>
                      <base-button
                        slot="reference"
                        class="p-0 m-0 shadow-none mr-2 mt-n4"
                        outiline
                        size="sm"
                        type="secundary"
                      >
                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                      </base-button>
                    </el-popover>

                    <base-switch
                      v-model="generalSettings.is_municipal_work_code_required_prop_contracts"
                      type="primary"
                      offText="Não"
                      onText="Sim"
                      class="success" />
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Exigir preenchimento de art/encapsulamento em propostas/contratos
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <el-popover v-if="audits?.is_art_encapsulation_required?.user_name"
                                class="ml-0"
                                placement="bottom"
                                trigger="click"
                    >
                      <h5 style="margin-bottom: -1px;font-weight: 400 !important;">
                        <span >{{ audits.is_art_encapsulation_required.latest_updated_at | parseDate('DD MMM YYYY - HH:mm') }} <br> {{ audits.is_art_encapsulation_required.user_name}}</span>
                      </h5>
                      <base-button
                        slot="reference"
                        class="p-0 m-0 shadow-none mr-2 mt-n4"
                        outiline
                        size="sm"
                        type="secundary"
                      >
                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                      </base-button>
                    </el-popover>

                    <base-switch
                      v-model="generalSettings.is_art_encapsulation_required"
                      type="primary"
                      offText="Não"
                      onText="Sim"
                      class="success" />
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Permite realizar carregamento simultâneos
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <el-popover v-if="audits?.is_concurrent_loading_allowed?.user_name"
                                class="ml-0"
                                placement="bottom"
                                trigger="click"
                    >
                      <h5 style="margin-bottom: -1px;font-weight: 400 !important;">
                        <span >{{ audits.is_concurrent_loading_allowed.latest_updated_at | parseDate('DD MMM YYYY - HH:mm') }} <br> {{ audits.is_concurrent_loading_allowed.user_name}}</span>
                      </h5>
                      <base-button
                        slot="reference"
                        class="p-0 m-0 shadow-none mr-2 mt-n4"
                        outiline
                        size="sm"
                        type="secundary"
                      >
                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                      </base-button>
                    </el-popover>

                    <base-switch
                      v-model="generalSettings.is_concurrent_loading_allowed"
                      type="primary"
                      offText="Não"
                      onText="Sim"
                      class="success" />
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Prazo para início de carregamento
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-5 pr-4 mb-2">
                  <validation-provider rules="required|min_value:1" v-slot="{errors}">

                    <base-input input-group-classes="input-group-sm">

                      <el-popover v-if="audits?.schedule_start_load_time?.user_name"
                                  class="ml-0"
                                  placement="bottom"
                                  trigger="click"
                      >
                        <h5 style="margin-bottom: -1px;font-weight: 400 !important;">
                          <span >{{ audits.schedule_start_load_time.latest_updated_at | parseDate('DD MMM YYYY - HH:mm') }} <br> {{ audits.schedule_start_load_time.user_name}}</span>
                        </h5>
                        <base-button
                          slot="reference"
                          class="p-0 m-0 shadow-none mr-2"
                          outiline
                          size="sm"
                          type="secundary"
                        >
                          <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                        </base-button>
                      </el-popover>
                      <input
                        v-on:input="
                          generalSettings.schedule_start_load_time = formatString(
                            $event.target.value,
                            '0',
                            '99',
                            ''
                          )
                        "
                        v-model="generalSettings.schedule_start_load_time"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          horas
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Limite de edição do horário de entrega por viagem
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-5 pr-4 mb-2">
                  <validation-provider rules="required|min_value:1" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <el-popover v-if="audits?.schedule_travel_edit_time?.user_name"
                                  class="ml-0"
                                  placement="bottom"
                                  trigger="click"
                      >
                        <h5 style="margin-bottom: -1px;font-weight: 400 !important;">
                          <span >{{ audits.schedule_travel_edit_time.latest_updated_at | parseDate('DD MMM YYYY - HH:mm') }} <br> {{ audits.schedule_travel_edit_time.user_name}}</span>
                        </h5>
                        <base-button
                          slot="reference"
                          class="p-0 m-0 shadow-none mr-2"
                          outiline
                          size="sm"
                          type="secundary"
                        >
                          <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                        </base-button>
                      </el-popover>
                      <input
                        v-on:input="
                          generalSettings.schedule_travel_edit_time = formatString(
                            $event.target.value,
                            '0',
                            '99',
                            ''
                          )
                        "
                        v-model="generalSettings.schedule_travel_edit_time"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          horas
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Limite máximo permitido para exclusão de programação
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-5 pr-4 mb-2">
                  <validation-provider rules="required|min_value:1" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <el-popover v-if="audits?.schedule_delete_time?.user_name"
                                  class="ml-0"
                                  placement="bottom"
                                  trigger="click"
                      >
                        <h5 style="margin-bottom: -1px;font-weight: 400 !important;">
                          <span >{{ audits.schedule_delete_time.latest_updated_at | parseDate('DD MMM YYYY - HH:mm') }} <br> {{ audits.schedule_delete_time.user_name}}</span>
                        </h5>
                        <base-button
                          slot="reference"
                          class="p-0 m-0 shadow-none mr-2"
                          outiline
                          size="sm"
                          type="secundary"
                        >
                          <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                        </base-button>
                      </el-popover>
                      <input
                        v-on:input="
                          generalSettings.schedule_delete_time = formatString(
                            $event.target.value,
                            '0',
                            '99',
                            ''
                          )
                        "
                        v-model="generalSettings.schedule_delete_time"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          horas
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    ACRÉSCIMO NO CUSTO POR PERDA DE MOVIMENTAÇÃO DE MCC
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-5 pr-4 mb-2">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <el-popover v-if="audits?.increased_loss_cost_mcc?.user_name"
                                  class="ml-0"
                                  placement="bottom"
                                  trigger="click"
                      >
                        <h5 style="margin-bottom: -1px;font-weight: 400 !important;">
                          <span >{{ audits.increased_loss_cost_mcc.latest_updated_at | parseDate('DD MMM YYYY - HH:mm') }} <br> {{ audits.increased_loss_cost_mcc.user_name}}</span>
                        </h5>
                        <base-button
                          slot="reference"
                          class="p-0 m-0 shadow-none mr-2"
                          outiline
                          size="sm"
                          type="secundary"
                        >
                          <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                        </base-button>
                      </el-popover>
                      <input
                        v-mask="'#,##'"
                        v-model="generalSettings.increased_loss_cost_mcc"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                        :class="!generalSettings.increased_loss_cost_mcc ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          %
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal()">
                  Cancelar
                </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  v-bind:disabled="invalid"
                  :loading="loadingSave"
                >
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import { checkNumberValue } from "@/helpers";
import { mask1, formatErrorValidation } from "@/plugins";

export default {
  name: "ModalGeneralSettings",
  data() {
    return {
      title: "Configurações gerais",
      modal: false,
      loadingSave: false,
      audits: {},
      generalSettings: {
        schedule_start_load_time: null,
        schedule_delete_time: null,
        schedule_travel_edit_time: null,
        company_plant_uuid: null,
        order_service_time: null,
        nfe: true,
        is_cno_required_prop_contracts: false,
        is_municipal_work_code_required_prop_contracts: false,
        is_art_encapsulation_required: false,
        is_concurrent_loading_allowed: false,
        increased_loss_cost_mcc: null,
      }
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(uuid) {
      this.$Progress.start();
      this.$store
        .dispatch("plant/getGeneralSettings", uuid)
        .then(response => {
          this.generalSettings = {
            schedule_start_load_time:
              response.data.schedule_start_load_time ?? null,
            schedule_delete_time: response.data.schedule_delete_time ?? null,
            order_service_time: response.data.order_service_time ?? null,
            nfe: response.data.nfe ?? true,
            is_cno_required_prop_contracts: response.data.is_cno_required_prop_contracts ?? false,
            is_municipal_work_code_required_prop_contracts: response.data.is_municipal_work_code_required_prop_contracts ?? false,
            is_art_encapsulation_required: response.data.is_art_encapsulation_required ?? false,
            is_concurrent_loading_allowed: response.data.is_concurrent_loading_allowed ?? false,
            increased_loss_cost_mcc: response.data.increased_loss_cost_mcc ?? null,
            schedule_travel_edit_time:
              response.data.schedule_travel_edit_time ?? null,
            company_plant_uuid: response.data.company_plant_uuid ?? uuid,
          };
          this.audits = response.data.audits ?? {}
          this.$Progress.finish();
          this.modal = true;
        })
        .catch(error => {
          this.generalSettings = {
            schedule_start_load_time: null,
            schedule_delete_time: null,
            schedule_travel_edit_time: null,
            nfe: true,
            is_cno_required_prop_contracts: false,
            is_municipal_work_code_required_prop_contracts: false,
            is_art_encapsulation_required: false,
            is_concurrent_loading_allowed: false,
            company_plant_uuid: uuid,
          };
          this.modal = true;
          this.$Progress.finish();
        });
    },
    save() {
      this.$Progress.start();
      this.loadingSave = true;
      let generalSettings = {...this.generalSettings}
      generalSettings.schedule_delete_time = parseInt(
        generalSettings.schedule_delete_time
      );
      generalSettings.schedule_start_load_time = parseInt(
        generalSettings.schedule_start_load_time
      );
      generalSettings.schedule_travel_edit_time = parseInt(
        generalSettings.schedule_travel_edit_time
      );
      generalSettings.increased_loss_cost_mcc = parseFloat(generalSettings.increased_loss_cost_mcc.toString().replace(',', '.'))
      this.$store
        .dispatch("plant/addGeneralSettings", generalSettings)
        .then(response => {
          this.loadingSave = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.closeModal();
        })
        .catch(error => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingSave = false;
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
          }
          this.$Progress.finish();
          this.loadingSave = false;
        });
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
};
</script>

<style scoped></style>
